@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  background-color: #2d3748; /* This is the color code for bg-gray-800 in Tailwind CSS */
}

#root,
#app {
  background-color: #2d3748;
}

a:hover {
  color: #ffd700; /* Adjust this color value to the desired shade of yellow */
}

/* Universal styled scrollbar */

/* Width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(176, 174, 174);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
